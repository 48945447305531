import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import s3 from '../../aws-s3';
import { useRecipeState, RecipeService } from '../../Service/RecipeServices';
import S3FileUpload from 'react-s3';
import moment from 'moment';
import { FaCircle } from "react-icons/fa";
import Loader from '../Loader';
import { useUserState, UsersService } from '../../Service/userServices';
import VideoCarousel from './VideoCarousel';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useAuth } from '../../Context/AuthContext';
import { toast, Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AWS from 'aws-sdk'
import S3 from 'react-aws-s3';
import CustomButton from '../../utils/customButton';
import BriefTemplateView from './BriefTemplateView';
import useSubmitStatus from '../../hooks/useSubmitStatus';
import useFileUploader from '../../hooks/useFileUploader';
import FileProgressBar from '../FileProgressBar';
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
});
const config = {
  region: 'us-east-1',
  bucketName: 'crafted-video-assets',
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
  s3Url: 'https://crafted-video-assets.s3.amazonaws.com',
}
const ReactS3Client = new S3(config);

const elastictranscoder = new AWS.ElasticTranscoder();

const createJob = async (file) => {
  console.log("file name: ", file)
  const fileKey = file.replace("https://crafted-video-assets.s3.amazonaws.com/", "").replace(/\s/g, '_')
  const params = {
    "Input": {
      "Key": fileKey,
      "FrameRate": "auto",
      "Resolution": "auto",
      "AspectRatio": "auto",
      "Interlaced": "auto",
      "Container": "mp4"
    },
    "Outputs": [
      {
        "Key": `${fileKey.replace(/\.mov$/i, '.mp4').replace(/\s/g, '_')}`,
        "Rotate": "0",
        "PresetId": "1351620000001-000001"
      }
    ],
    "PipelineId": "1712849456709-w50a8j"
  };
  //console.log("params: ", params)

  return new Promise((resolve, reject) => {
    elastictranscoder.createJob(params, function(err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        reject(err);
      } else {
        console.log(data);           // successful response
        resolve(data);
      }
    });
  });
}
const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)',

  },
};


const StringChecker = (text) => {
  let formattedText = text;
  if (formattedText[0].length > 50) {
    formattedText[0] = formattedText[0].substring(0, 50) + '...';
  }
  return (formattedText[0]);
};

const Variation = ({ variation, handleVariationChange }) => {
  const {
    files,
    inputRef,
    progress,
    uploadError,
    uploadHanlder,
    handleRetryUpload
   } = useFileUploader();

   useEffect(() => {
    if(files) {
      const updatedVariation = { ...variation, files };
      handleVariationChange(updatedVariation)
    }
   }, [files])

  return (
    <div className='mb-2' key={variation.id}>
      <h3 className='font-bold flex items-center gap-3'>
        Version {variation.id + 2}: <FileProgressBar progress={progress} />
          {uploadError ? 
          
          <div className="flex gap-3 items-center">
            <span className="text-xs text-danger">{uploadError}</span>
            <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryUpload}
            >Retry
            </span>
          </div>
          :null}
      </h3>
      <div className='flex w-full 2xl:text-base md:text-base xs:tracking-tighter xs:text-0.7'>
        Upload the version with {Object.entries(variation)
          .filter(([key, value]) => value === true || value === false)
          .map(([key, value], index, array) => (
            <>
          
          <span key={key} className="ml-1">
            {key === "voiceover" && (variation[key] === true ? <div className='bg-blue-deep px-2 rounded-md text-white'>Voiceover</div> : <div className='bg-gray-500 rounded-md px-2 text-white'>No Voiceover</div>)}
            {key === "music" && (variation[key] === true ? <div className='bg-yellow-600 rounded-md px-2 text-white'>Background Music</div> : <div className='bg-gray-500 rounded-md px-2 text-white'>No Background Music</div>)}
            {key === "onScreenText" && (variation[key] === true ? <div className='bg-purple px-2 rounded-md text-white'>On-screen text</div> : <div className='bg-gray-500 rounded-md px-2 text-white'>No On Screen text</div>)}                  
            {key === "subtitles" && (variation[key] === true ? <div className='bg-yellow-400 px-2 rounded-md text-white'>Subtitles</div> : <div className='bg-gray-500 rounded-md px-2 text-white'>No Subtitles</div>)}
          </span>
            </>
          ))}
      </div>     
      <input
        id="videoInput"
        type="file"
        required
        accept="video/mov, video/mp4, "
        className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
        onChange={(e) => uploadHanlder(e)}
        ref={inputRef}
      />

    </div>
  )
}
const TaskDetails = (props) => {
  const fileRefs = useRef({});
  const taskState = useTaskState();
  const [videos, setVideos] = useState([]);
  const [variations, setVariations] = useState([]);
  const [metaRequested, setMetaRequested] = useState(false);
  const [final, setFinal] = useState(false);
  const [recipeId, setRecipeId] = useState(null);
  const [tiktokCode, setTiktokCode] = useState('');
  const [briefView, setBriefView] = useState(false);
  const userState = useUserState();
  const { currentUser } = useAuth();
  let videoLinks = {};
  let s3PhotoLink = {};
  let s3ReviewLink = {};
  let s3Variations = [];
  let s3RawFootage = [];
  //const [videosUploaded, setVideoUploads] = useState(false);
  var uploadedVideos = {};
  let video1 = '';
  let video2 = '';
  let products = [];
  const timezone = moment.tz.guess();

  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
        [e[0]]: e[1]
      }))
      : [];
  }

  useEffect(() => {
    if (userState.updateNeed.value) {
      UsersService.FetchBasicInfo(currentUser.uid);
    }
  }, [userState.updateNeed.value]);

  const viewBriefHandle = () => {
    briefView ? setBriefView(false) : setBriefView(true);
  }

  
  const user = userState.user.value;

  const userProposals = [];
  const userProposal = Object.values(props.task.accepted_proposals).find(proposal => proposal.creator_id === currentUser.uid);

  for (const proposalId in props.task.proposals) {
    const proposal = props.task.proposals[proposalId];
    if (proposal.creator_id === currentUser.uid) {
      for (const key in proposal.proposal_details) {
        userProposals.push(proposal.proposal_details[key]);
      }
    }
  }
  const recipeState = useRecipeState();
  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(currentUser.uid);

    }
  }, [recipeState.updateNeed.value]);
  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
      [e[0]]: e[1]
    }))
    : [];
  // 1 - set num_videos

  var num_videos = props.task.recipe_video + props.task.product_showcase;
  if (num_videos <= 0) {
    console.error('Error: num_videos must be a positive integer');
    return null;
  }

  // 2 - generate appropriate number of video inputs

  // 3 - handleChange that updates a useState element with true/false for upload

  // 4 - Iterate through each element in array of videos & upload to S3, set the result to another variable

  // 5 - place the object into the object for submit task
  // This will create an array of proposalData objects
const proposalDataArray = Object.entries(props.task.proposals).map(([proposalId, proposalData]) => {
  return proposalData; // return the proposalData for each entry
});

const fulfillment =
    props.task.accepted_proposals &&
    props.task.accepted_proposals[currentUser.uid] &&
    props.task.accepted_proposals[currentUser.uid].fulfillment;

  const fulfillmentType =
    props.task.accepted_proposals &&
    props.task.accepted_proposals[currentUser.uid] &&
    props.task.accepted_proposals[currentUser.uid].fulfillment &&
    props.task.accepted_proposals[currentUser.uid].fulfillment.type;

// Now proposalDataArray is an array of proposalData objects
console.log('creator_name: ', proposalDataArray[0].creator_name);


  const {
    retrySubmit,
    setRetrySubmit,
    isSubmitting,
    setIsSubmitting
  } = useSubmitStatus();

  const {
    files: files,
    inputRef: videoInputRef,
    progress: videoUploadProgress,
    uploadError: videoUploadError,
    uploadHanlder: uploadVideoHandler,
    handleRetryUpload: handleRetryVideoUpload
   } = useFileUploader();

   const {
    files: photo,
    inputRef: photoInputRef,
    progress: photoUploadProgress,
    uploadError: photoUploadError,
    uploadHanlder: uploadPhotoHandler,
    handleRetryUpload: handleRetryPhotoUpload
   } = useFileUploader();

   const {
    files: review,
    inputRef: reviewInputRef,
    progress: reviewUploadProgress,
    uploadError: reviewUploadError,
    uploadHanlder: uploadReviewHandler,
    handleRetryUpload: handleRetryReviewUpload
   } = useFileUploader();

   const {
    files: raw,
    inputRef: rawInputRef,
    progress: rawUploadProgress,
    uploadError: rawUploadError,
    uploadHanlder: uploadRawHandler,
    handleRetryUpload: handleRetryRawUpload
   } = useFileUploader();

   console.log(files, photo,)


  const handleVariationChange = (files) => {
    setVariations(files);
  };
  const handlePhotoChange = (event) => {
    if (event.target.files.length > 3) {
      // Notify the user that only 3 files can be selected
      alert('You can only select up to 3 files');
      // Clear the selected files
      event.target.value = '';
    } else {
      uploadPhotoHandler(event)
    }
  };

  const handleAddVideos = (e, i) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideos({
        ...videos,
        [`video${i}`]: e.target.files[0]
      });
    }
  };
  //create job for transcoder to convert video that's mov to mp4

  /* const handleChange1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVid1Upload(true);
    } else {
      setVid1Upload(false);
    }
  };
  const handleChange2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVid2Upload(true);
    } else {
      setVid2Upload(false);
    }
  };*/

  useEffect(() => {
    console.log('recipeId', recipeId);
  }, [recipeId]);

  async function sendDeliverables(event) {
    setIsSubmitting(true);
    event.preventDefault();
    try {
      taskState.merge({ loading: true });
      let date = moment().format('MMM_DD_YYYY_HHmmss');
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        console.log('File #' + i);
        console.log(file);
        file = new Blob([file], { type: file.type });
        file.name = files[i].name.replace(/\s/g, '_');
        const userName = user.name || (user && user.shipping_details && user.shipping_details.fullname) || user.username;
        const brandName = props.task.brand_name; // Replace with your actual brand name
        const newName = `${userName}_${brandName}_${date}${file.name.substr(file.name.lastIndexOf('.'))}`.replace(/ /g, '_');
  
        // Create a new File object with the new name
        file = new File([file], newName, { type: file.type });
  
        try {
          const data = await ReactS3Client.uploadFile(file, newName);
          console.log('File String #' + i);
          console.log(data.location);

          if (file.name.toLowerCase().endsWith('.mov')) {
            // Transcode the file
            await createJob(data.location);
        
            // Replace the .mov file with the .mp4 file in the videoLinks array
            videoLinks[`video${i + 1}`] = data.location.replace(/\.mov$/i, '.mp4').replace(/\s/g, '_');
          } else {
            videoLinks[`video${i + 1}`] = data.location.replace(/\s/g, '_');
          }
        } catch (error) {
          console.error(`Failed to upload file: ${file.name}`, error);
        }
      }
    if (variations.length > 0) {
    for (let i = 0; i < variations.length; i++) {
      const variation = variations[i];
      
      if (variation.separateClips === true) {
        const filesToUpload = variation.files;
        const uploadedFiles = await Promise.all(filesToUpload.map(file => {
          file = new Blob([file], { type: file.type });
          file.name = file.name.replace(/\s/g, '_');
          return S3FileUpload.uploadFile(file, s3);
        }));
        
        const variationData = {
          files: uploadedFiles.map(d => d.location), // Store all file locations in an array
          id: variation.id,
          music: variation.music,
          onScreenText: variation.onScreenText,
          separateClips: variation.separateClips,
          voiceover: variation.voiceover
        };
      
        s3Variations.push(variationData); // Push the variationData directly
      } else {
        console.log('variation with one file: ', variation);
        let fileToUpload = Array.isArray(variation.files) ? variation.files[0] : variation.files;
        console.log('variation with one file: ', fileToUpload);

        // Create a new name
        const userName = user.name || (user.shipping_details && user.shipping_details.fullname) || user.username;
        const brandName = props.task.brand_name; // Replace with your actual brand name
        let dateTime = moment().format('MMM_DD_YYYY_HHmmss');
        const newName = `${userName}_${brandName}_version${i+2}_${dateTime}${fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.'))}`.replace(/ /g, '_');

        // Create a new File object with the new name
        fileToUpload = new File([fileToUpload], newName, { type: fileToUpload.type });

        const uploadedFile = await S3FileUpload.uploadFile(fileToUpload, s3);
        const variationData = {
          files: uploadedFile.location, // Store the uploaded file location
          id: variation.id,
          music: variation.music,
          onScreenText: variation.onScreenText,
          separateClips: variation.separateClips,
          voiceover: variation.voiceover
        };

        s3Variations.push(variationData);
    }
  }
}

      if (raw.length > 0) {
        for (let i = 0; i < raw.length; i++) {
          const file = raw[i];
          const data = await S3FileUpload.uploadFile(file, s3);
          s3RawFootage.push(data.location.toString());
        }
      }
      if (review.length > 0) {    
      for (let i = 0; i < review.length; i++) {
        const file = review[i]; // Use `file` instead of `review_file`
        console.log('File #' + i);
        console.log(file);
        const data = await S3FileUpload.uploadFile(file, s3);
        console.log('File String #' + i);
        console.log(data.location);
      
        s3ReviewLink[`review${i + 1}`] = data.location.toString();
      }
    }

      if (photo. length > 0) {    
        for (let i = 0; i < photo.length; i++) {
          const file = photo[i];
          const data = await S3FileUpload.uploadFile(file, s3);
          s3PhotoLink[`photo${i + 1}`] = data.location.toString();
        }
        
        // After uploading all files, check if the number of files exceeds three
        if (photo.length !== 3) {
          // Notify the user that only 3 files can be uploaded
          alert('You can only upload up to 3 files');
          setIsSubmitting(false);
          return; // Exit the function or handle the error accordingly
        }
    }


      let vidname =
      props.task.brand_name + '_' +  
      ((user && user.name) || (user && user.shipping_details && user.shipping_details.fullname) || (user && user.username)) + 
      '_' + date.substring(4, 21);
        let d = {
          photoLinks: s3PhotoLink,
          reviewLinks: s3ReviewLink,
          drafts: videoLinks,
          rawFootage: s3RawFootage,
          creator_id: props.task.creator_id ? props.task.creator_id : props.task.brand_id,
          task_id: props.task.id,
          creator_name: vidname,
          creator_id: currentUser.uid,
          brand_id: props.task.brand_id,
          recipe: recipeId !== null ? recipeId : null,
          variations: s3Variations,
        };
        console.log("DATA", d);
      await TaskService.SendDraft(props.id[0], d);
      taskState.merge({ loading: false });
      toast.success('Deliverables Sent', {duration: 1800});
      setTimeout(() => {
        setRetrySubmit(false)
        props.close();
      } , 2000);
      // close modal
    } catch (error) {
      setRetrySubmit(true)
      console.error(error);
      toast.error(error.message || "Something went wrong", { duration: 3000 });
    } finally {
      // Reset the submitting state if needed
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    console.log("variations updated:", variations);
  }, [variations]);
  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: '' };
  }
  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (props.task && props.task.note3) {
      if (regex.test(props.task.note3)) {
        let extractedUrl = props.task.note3.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(props.task.note3);
        return (
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className="max-w-full text-blue-500" >
              <a className='text-blue-500 hover:underline cursor-pointer' href={props.task.note3.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" style={{ color: 'blue' }}>{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else {
        return (
          <div>
            {props.task.note3}
          </div>
        )
      }
    }
  }

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setRecipeId(selectedValue);
    console.log(selectedValue);
  };
  
  return (
    <div> 
      <Toaster/>
      <Modal
        open={props.open}
        onClose={props.close}
        center
        style = {{width: '20%'}}
        classNames={{ modal: 'customModalTwo' }}
        closeOnOverlayClick={true}>
        <>
          <Collapsible
            trigger={[
              'Task Instructions',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Task Instructions',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a
                  className="text-blue underline"
                  target="_blank"
                  href={`http://${props.task && props.task.brand_website}`}
                  rel="noreferrer">
                  {props.task && props.task.brand_name}
                </a>
              </div>

              {/*<div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && task_deadline_time}</div>
              </div>*/}
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brief:</div>
                <div className="flex flex-col space-y-2">
                  <div>
                    {' '}
                    {props.task &&
                      props.task.recipe_videos > 0 &&
                      `Create ${props.task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    {' '}
                    {props.task &&
                      props.task.product_showcase > 0 &&
                      `Create ${props.task.product_showcase} short form product demo videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    <div className="mt-2 font-bold">
                      Key talking points for creators:
                    </div>
                    <div className="">
                      {covertingString()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-12 mb-5">
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">{props.task && props.task.note1}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name}'s Target Audience:
                  </div>
                  <div className="">{props.task && props.task.note2}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">Key talking points for creators:</div>
                  <div className="">{props.task && props.task.note3}</div>
                </div>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'My Proposal + Sample Products',
              <div className="px-3 flex flex-row gap-4 absolute right-0">
               {props.currentUserHasFulfillment &&  <FaCircle className='text-red'/>}
              <BsChevronCompactDown />  
              </div>
            ]}
            triggerWhenOpen={[
              'My Proposal + Sample Products',
              <div className="px-3 flex flex-row gap-4 absolute right-0">
                 {props.currentUserHasFulfillment &&  <FaCircle className='text-red'/>}
              <BsChevronCompactUp />  
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
              {/*<div className="flex mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && task_deadline_time}</div>
              </div>*/}
              <div className="flex flex-col mb-5">
                <div className="pb-1 font-bold">My Proposal: </div>
                <div className="p-3">
                { props.task.accepted_proposals.proposal1 || userProposal.proposal_details.proposal1}
                </div>
              </div>
              <div className="flex mb-5 flex-col">
                <div className="flex mr-5 font-bold">Products:</div>
                <div>
               {props.task && props.task.note1}  
               {fulfillment && fulfillmentType === "shipped" && (
                 <span style={{ margin: 0, padding: 0 }}>
                     &nbsp; will be shipped to your address.
                   <div>
                     <a
                       className="text-red font-bold"
                       href={`https://parcelsapp.com/en/tracking/${fulfillment.tracking_number}`}
                       rel="noreferrer"
                       target="_blank"
                       style={{ color: "red", marginBottom: "0.5em" }}
                     >
                       Track Your Package
                     </a>
                   </div>
                 </span>
               )}
                </div>
             
                {fulfillment && fulfillmentType == "store" &&
                <ul>
                    <li>You will be purchasing your product in stores, please refer to this store list (see below) to find the location nearest you.</li>
                    <li>The brand will be issuing you a credit for ${(fulfillment.product_price)} to reimburse upon completion of the task.</li>
                    <li>Retailer:{fulfillment.retailer}</li>
                    <li>
                      <a
                          className="text-red font-bold"
                          href={`${fulfillment.store_link && fulfillment.store_link}`}
                          rel="noreferrer"
                          target="_blank">
                          Find Nearest Store Location
                      </a>
                    </li>
                </ul>}
                {fulfillment && fulfillmentType == "promo" &&
                <ul>
                    <li >You will be purchasing your product online with a promo code, please find the link to buy the product and the promo code below.</li>
                    <li>Promo Code: {fulfillment.promo_code}</li>
                    <li>
                      <a
                          className="text-red font-bold"
                          href={`${fulfillment.product_link && fulfillment.product_link}`}
                          rel="noreferrer"
                          target="_blank">
                          Buy Product Here
                      </a>
                    </li>
                </ul>}
                {/* <div>{props.task.products}</div> */}
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3">
              <VideoCarousel />
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Send Deliverables',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Send Deliverables',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={true}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <form onSubmit={sendDeliverables}> 
            <div className="flex flex-col my-5">
              <div className="flex flex-col space-y-2 mb-3">
                <div className="font-bold">Checklist before Submission:</div>
                <div className="list-disc px-4">
                  <li>Shot with natural lighting or good overhead kitchen lights</li>
                  <li>Video quality is clear and not grainy</li>
                  <li>Voiceover is highly energetic, genuine and includes personal anecdotes</li>
                  <li>
                    Correct product benefits stated, key talking points covered, prepared product
                    correctly
                  </li>
                  <li>Video falls into one the video types above and executed perfectly</li>
                  <li> Submission is the final video, not something that is labeled as #draft or is a screen recording from Capcut.</li>
                </div>
              </div>
             
              <div className="font-bold text mr-2">
  
            
              <div className="flex flex-col my-5">
                 {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Recipes") && (
                <div>
                  <div className="mt-2 flex w-fit items-center relative group"><p className='font-bold'>Add Recipe:</p><AiOutlineQuestionCircle className='ml-1'/>
                    <div className="absolute border font-thin border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-56 h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                        Recipes must be added in the "Recipes" page before submitting a recipe.
                    </div>
                  
                  
                  </div>
                  {recipe && recipe.length > 0 ? (
                    <select 
                      required 
                      className="mt-4 mb-2 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                      onChange={handleSelectChange}
                    >
                      <option value="" className="text-gray-400">
                        Select a recipe
                      </option>
                      {recipe.map((item) => {
                        const res = item[Object.keys(item)[0]];
                        const id = Object.keys(item)[0];
                        return (
                          <option key={id} value={id}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select> 
                  ) : (
                    <h1 className="font-thin my-3">
                      You currently have no recipes. Please add a recipe{" "}
                      <Link to="/creator/dashboard/recipes" className="underline text-red font-bold">here</Link>
                    </h1>
                  )}

                </div>
              )}

              {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Reviews") && (
                <div>       
                <h3 className='font-bold flex items-center gap-3'>Screenshot of Product Review:
                <FileProgressBar progress={reviewUploadProgress} />
                {reviewUploadError ? 
                
                <div className="flex gap-3 items-center">
                  <span className="text-xs text-danger">{reviewUploadError}</span>
                  <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryReviewUpload}
                  >Retry
                  </span>
                  </div>
                :null}</h3> 
                <input
                  id="screenshotInput"
                  type="file"
                  required
                  accept="image/png, image/jpeg"
                  className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                  onChange={(e) => uploadReviewHandler(e)}
                  ref={reviewInputRef}
                />
                </div>
              )}

                  {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Photos") && (
                    <div>     
                       <h3 className="flex items-center gap-3">Add three Product Photos: <FileProgressBar progress={photoUploadProgress} />
                          {photoUploadError ? 
                          
                          <div className="flex gap-3 items-center">
                            <span className="text-xs text-danger">{photoUploadError}</span>
                            <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryPhotoUpload}
                            >Retry
                            </span>
                            </div>
                          :null}
                        </h3>
                      <input
                        id="imageInput"
                        type="file"
                        required
                        accept="image/png, image/jpeg, image/jpg"
                        className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                        multiple
                        max="3"
                        onChange={(e) => handlePhotoChange(e)}
                        ref={photoInputRef}
                      />
                    </div>
                  )}

         
              <p className='flex items-center gap-3'>Version 1: <FileProgressBar progress={videoUploadProgress} />
                    {videoUploadError ? 
                    
                    <div className="flex gap-3 items-center">
                      <span className="text-xs text-danger">{videoUploadError}</span>
                      <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryVideoUpload}
                      >Retry
                      </span>
                      </div>
                     :null}</p>
              {props.task.video_requirements && 
                <p className='2xl:text-base flex gap-2  md:text-base xs:tracking-tighter xs:text-0.7'>Upload the version with 
                 
                  {props.task.video_requirements.music ? <div className="bg bg-yellow-600 rounded-md px-2 text-white">&nbsp;Background Music{props.task.video_requirements.onScreenText ? ' ' : ''}</div>: <div className="bg-gray-500 rounded-md px-2 text-white"> &nbsp;No Background Music{props.task.video_requirements.onScreenText ? ' ' : ''}</div>}
                  {props.task.video_requirements.onScreenText ? <div className="bg-purple px-2 rounded-md text-white">&nbsp;On Screen Text{props.task.video_requirements.subtitles ? ' ' : ''}</div> : <div className="bg-gray-500 rounded-md px-2 text-white"> &nbsp;No On Screen Text{props.task.video_requirements.subtitles ? ' ' : ''}</div>} 
                  {props.task.video_requirements.subtitles ? <div className=" bg-yellow-400 px-2 rounded-md text-white">&nbsp;Subtitles</div>: <div className="bg-gray-500 rounded-md px-2 text-white"> &nbsp;No Subtitles</div>}
                  {props.task.video_requirements.voiceover ? <div className="bg-blue-deep px-2 rounded-md text-white">&nbsp;Voiceover{props.task.video_requirements.music|| props.task.video_requirements.onScreenText ? ' ' : ''}</div> : <div className="bg-gray-500 rounded-md px-2 text-white"> No Voiceover{props.task.video_requirements.music|| props.task.video_requirements.onScreenText ? ' ' : ''}</div>}</p>
                }
                <p className="text-xs font-light">Only accepts png, jpeg, mp4. 2GB limit</p>
                <input
                  id="imageInput"
                  type="file"
                  accept="video/mov, video/mp4"
                  className="focus:outline-none w-full  bg-lightGray p-2 mt-2 mr-2 rounded"
                  required
                  onChange={(e) => uploadVideoHandler(e)}
                  ref={videoInputRef}
                />



                {props.task && props.task.variations && (
                  <div className='mt-2'>     
                    {props.task.variations.map((variation, index) => (
                      <Variation variation={variation} key={index} handleVariationChange={handleVariationChange}/>
                    ))}
                  </div>
                )}
                {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Raw Footage") && (
                <div>       
                <h3 className='font-bold flex items-center gap-3'>Raw Footage <FileProgressBar progress={rawUploadProgress} />
                    {rawUploadError ? 
                    
                    <div className="flex gap-3 items-center">
                      <span className="text-xs text-danger">{rawUploadError}</span>
                      <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryRawUpload}
                      >Retry
                      </span>
                      </div>
                     :null}</h3> 
                <p className='text-base font-thin'><strong  className='font-bold'>NOTE:</strong> For raw footage, you must include the unedited clips (the video clips from your camera roll) that make up your video. You can upload multiple files.</p>
                <input
                id="screenshotInput"
                type="file"
                multiple
                required
                accept="video/mov, video/mp4"
                className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                onChange={(e) => uploadRawHandler(e)}
                ref={rawInputRef}
              />
                </div>
              )}
                {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                    addOn.name === "Whitelist" && addOn.platform.includes("tiktok")) &&
                    <div className='my-2'>For TikTok Whitelisting, you will generate a TikTok Spark Code after the video assets are approved.</div>
                }
                {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                    addOn.name === "Whitelist" && addOn.platform.includes("instagram")) &&
                    <div className='my-2'>
                      <label className="inline-flex items-center mr-4">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-gray-600"
                          checked={metaRequested}
                          required
                          onChange={() => setMetaRequested(!metaRequested)}
                        />
                        <span className="ml-2 text-base xs:text-0.7 w-full text-gray-700"> For Instagram Whitelisting, I have accepted {props.task.brand_name} as an Ad Partner through Meta Business Suite:  <a href="https://help.instagram.com/966886840676703" target ="_blank" className="text-Blue underline">See Instructions</a></span>
                      </label>
                    
                    </div>
                  }
                 <div className='my-2'>
                      <label className="inline-flex items-center mr-4">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-gray-600"
                          checked={final}
                          required
                          onChange={() => setFinal(!final)}
                        />
                        <span className="ml-2 text-base xs:text-0.65 w-full text-gray-700"> I am submitting a video file that can be used as a final deliverable - not a screen recording of the video from editing app or a video watermarked with #draft, etc. </span>
                      </label>
                    
                  </div>
              </div>
            </div>
            <div className="items-center  my-5 justify-center text-center">
            <CustomButton
                className=" text-white w-1/3 mt-2 py-4 xs:w-full outline-none text-base"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span>
                    <Loader /> 
                    <span className="mr-2">{retrySubmit ? "Retrying" : "Submitting"}...</span>
                   
                  </span>
                ) : (
                  retrySubmit ? "Retry Submit Deliverables" : "Submit Deliverables"
                 )}
              </CustomButton>
              
            </div> 
            </div>
          </form>
          {props.task.brief_template && (
            <div className='mx-auto items-center flex'>
                <CustomButton
                  className=" text-white xs:w-full mx-auto  mb-2 px-2 py-4 outline-none 2xl:text-base md:text-0.7 xs:text-sxs"
                  onClick={() => viewBriefHandle()}>
                  View Brief
                </CustomButton>
            </div>
              )}
               {briefView ? (
            <BriefTemplateView open={briefView} close={() => viewBriefHandle()} id={props.task.brief_template.id} task={props.task} />
          ) : null}
          </Collapsible>
        </>
      </Modal>
    </div>
  );
};
export default TaskDetails;
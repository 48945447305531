import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//state
const state = createState({
  authUser: [],
  authBrand: [],
  authAccount: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: false,
  updatedUser: null
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'AUTH_USER_INFO':
        return s.merge({
          authUser: action.data,
          loading: false,
          updateNeed: true,
          updatedUser: null
        });
        case 'AUTH_BRAND_INFO':
        return s.merge({
          authBrand: action.data,
          loading: false,
          updateNeed: true,
          updatedUser: null
        });
        case 'AUTH_ACCOUNT_INFO':
        return s.merge({
          authAccount: action.data,
          loading: false,
          updateNeed: true,
          updatedUser: null
        })
      case 'USER_AUTH_UPDATE':
        return s.merge({
          updateNeed: true,
          updatedUser: action.data,
          loading: false
        });
        case 'BRAND_AUTH_UPDATE':
        return s.merge({
          updateNeed: true,
          updatedUser: action.data,
          loading: false
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessAuthState = () => state;

export const userAuthstate = () => useState(state);

//service
export const AuthService = {
  FetchAuthUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users/${id}`)
      .then((res) => {
        dispatch(AuthUserAction.fetchAuthUser(res.data.result));
      })
      .catch((err) => {});
  },

  FetchAuthBrand : (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/brands/${id}`)
      .then((res) => {
        dispatch(AuthUserAction.fetchAuthBrand(res.data.result));
      })
      .catch((err) => {});
  },

  UpdateAuthUser: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .put(`${baseUrl}api/users/updateUser/${id}`, data)
      .then((res) => {
        dispatch(AuthUserAction.updateAuthUser(res.data));
      })
      .catch((err) => {});
  },
  UpdateAuthBrand: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .put(`${baseUrl}api/brands/updateBrand/${id}`, data)
      .then((res) => {
        dispatch(AuthUserAction.updateAuthBrand(res.data));
      })
      .catch((err) => {});
  },

  UpdateAuthAccount: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/accounts/updateAccount/${id}`, data)
      .then((res) => {
        dispatch(AuthUserAction.updateAuthAccount(res.data));
      }) 
      .catch((err) => {});
  }
};


//Action
const AuthUserAction = {
  fetchAuthUser: (data) => {
    return {
      type: 'AUTH_USER_INFO',
      data: data
    };
  },
  fetchAuthBrand: (data) => {
    return {
      type: 'AUTH_BRAND_INFO',
      data: data
    };
  },
  
  updateAuthBrand: (data) => {
    return {
      type: 'AUTH_BRAND_INFO',
      data: data
    };
  },

  updateAuthUser: (data) => {
    return {
      type: 'USER_AUTH_UPDATE',
      data: data
    };
  },

  updateAuthAccount: (data) => {
    return { 
      type: 'BRAND_AUTH_UPDATE',
      data: data
    }
  }
};

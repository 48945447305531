import React, { useState, useEffect, useContext } from "react";
import logo from "./../Assets/updated crafted logo.png";
import { useAuth } from "../Context/AuthContext";
import noAvatar from "./../Assets/profilePicture.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BiMenuAltRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import ProfileDropDown from "./ProfileDropDown";
import AuthModal from "./AuthModal";
import UpdateProfileModal from "./UpdateProfileModal";
import { CoinService } from "../Service/CoinServices";
import { AuthService, userAuthstate } from "../Service/authenticationService";
import { BrandService, useBrandState } from "../Service/BrandServices";
import { BrandContext } from "../routes/PrivateRoute/BrandDashboardRoute";

const BRAND_STOCK_ASSET_URL = process.env.REACT_APP_BRAND_ASSET_URL;

function Navbar() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDropModal, setOpenDropModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [link, setLink] = useState("/brands/dashboard");
  const { currentBrand, currentUser, logout } = useAuth();
  const { brandId } = useContext(BrandContext);
  const authState = userAuthstate();
  const brandState = useBrandState();
  const user = authState.authUser.value;
  const brand = authState.authBrand.value;
  const brandInfo = brandState.brand.value;
  const avatar = user ? user.avatar : null;
  const brandAvatar = brand ? brand && brand && brand.brand_avatar : null;

  useEffect(() => {
    const curUser = currentUser ? currentUser.uid : null;
    if (curUser) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setLink("/creator/dashboard");
    }
  }, [currentUser]);

  {
    /*useEffect(() => {
    const brand = currentBrand ? currentBrand.uid : null;
    if (brand) {
      AuthService.FetchAuthBrand(currentBrand && currentBrand.uid);
    } 
  }, [authState.updateNeed.value]);*/
  }

  useEffect(() => {
    if (brandId) {
      BrandService.FetchSingleBrand(brandId && brandId);
    }
  }, [brandState.updateNeed.value, brandId]);

  //console.log("brand info:", brandInfo);

  async function handleLogout() {
    localStorage.removeItem("brandId");
    logout()
      .then(() => {
        setNavbarOpen(false);
      })
      .catch((error) => {});
  }

  function handleOpenProfile() {
    if (!OpenProfileModal) {
      setOpenProfileModal(true);
    } else {
      setOpenProfileModal(false);
    }
  }

  return (
    <div>
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-1 dark:bg-gray-800 z-100">
        <div className="flex flex-wrap justify-between items-center">
          <Link to={Link} className="flex">
            <img
              src={logo}
              loading="eager"
              className="2xl:w-7rem md:w-4rem xs:w-10rem "
              alt=""
            />
          </Link>
          <div className="flex items-center md:order-2">
            <div className="hidden sm:flex sm:ml-6">
              {brandId && brandInfo && brandInfo.brand_avatar && (
                <div className="flex space-x-4 items-center">
                  <div className="mr-2 bg-gray-600 2xl:px-4 2xl:py-1 2xl:text-2xl md:px-2 md:py-1 md:text-0.7 text-white rounded-lg font-extrabold">
                    BRAND ACCOUNT
                  </div>
                  <div
                    className="flex items-center bg-red-plight py-1 px-2 rounded-full cursor-pointer relative"
                    onClick={() => setOpenDropModal(!OpenDropModal)}
                  >
                    <img
                      className="2xl:h-8 2xl:w-8 md:h-6 md:w-6 rounded-full object-cover"
                      src={
                        brandInfo.brand_avatar
                          ? brandInfo.brand_avatar.includes(
                              "crafted-react-images",
                            )
                            ? BRAND_STOCK_ASSET_URL
                            : brandInfo.brand_avatar
                          : BRAND_STOCK_ASSET_URL
                      }
                      alt="true"
                    />
                    <RiArrowDropDownLine className="text-red" />
                    {brandId && (
                      <div
                        className={
                          OpenDropModal ? "origin-top-right" : "hidden"
                        }
                      >
                        <ProfileDropDown brand={brandInfo} />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {currentUser && user && user.avatar && (
                <div>
                  <div className="flex space-x-4 items-center">
                    <div className="mr-2 bg-gray-600 2xl:px-4 2xl:py-1 2xl:text-2xl md:px-2 md:py-1 md:text-0.7 text-white rounded-lg font-extrabold">
                      CREATOR ACCOUNT
                    </div>
                    <div
                      className="flex items-center bg-red-plight py-1 px-2 rounded-full cursor-pointer relative"
                      onClick={() => setOpenDropModal(!OpenDropModal)}
                    >
                      <img
                        className="2xl:h-8 2xl:w-8 md:h-6 md:w-6 rounded-full object-cover"
                        src={
                          avatar
                            ? avatar.includes("crafted-react-images")
                              ? noAvatar
                              : avatar
                            : noAvatar
                        }
                        alt="true"
                      />
                      <RiArrowDropDownLine className="text-red" />
                      {user && (
                        <div
                          className={
                            OpenDropModal ? "origin-top-right" : "hidden"
                          }
                        >
                          <ProfileDropDown user={user} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              navbarOpen
                ? "z-50 my-4 text-base list-none bg-white absolute right-3 top-14 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
                : "hidden z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
            }
            id="dropdown"
          >
            <ul className="py-1" aria-labelledby="dropdown">
              <li>
                <button
                  className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  onClick={() => handleOpenProfile()}
                >
                  Update Profile
                </button>
              </li>
              <li>
                <button
                  className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  onClick={(e) => handleLogout(e)}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
          {currentUser ? (
            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <BiMenuAltRight className=" text-4xl text-black outline-none" />
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </nav>
      {openAuthModal && (
        <AuthModal
          isOpen={openAuthModal}
          closeAction={() => setOpenAuthModal(false)}
        ></AuthModal>
      )}

      {OpenProfileModal && (
        <UpdateProfileModal
          closeAction={() => {
            setOpenProfileModal(false);
          }}
        />
      )}
    </div>
  );
}

export default Navbar;

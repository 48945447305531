import React, { useState, useEffect } from "react";
import DeleteModalTask from "./TaskDeleteModal";
import InfluencerApprovalModalTask from "./InfluencerApprovalModalTask";
import TaskDetailsModal from "./SendTaskDeliverables";
import InfluencerSendDeliverables from "./InfluencerSendTaskDeliverables";
import TaskDeleteModal from "./TaskDeleteModal";
import moment from "moment";
import InfluencerSubmitLink from "./InfluencerSubmitLink";
import UpdateTask from "./UpdateTask";
import { updateCurrentUser } from "firebase/auth";
import { FiGift } from "react-icons/fi";
import { useUserState, UsersService } from "../../Service/userServices";
import { useAuth } from "../../Context/AuthContext";
import InfluencerSendVideoRevision from "./InfluencerSendVideoRevision";
import CustomButton from "../../utils/customButton";

const BRIEF_ASSET_URL = process.env.REACT_APP_BRIEF_ASSET_URL;

function TaskCard(props) {
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [videoRevision, setVideoRevision] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const [addPostModal, setAddPostModal] = useState(false);
  const [deliverables, setDeliverables] = useState(false);
  const { currentUser } = useAuth();
  const userState = useUserState();

  useEffect(() => {
    if (currentUser !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, []);
  const user = userState.user.value;
  const checkUserFulfillment = (approvedProposals, currentUserId) => {
    // First, check if approvedProposals is an object and not null
    if (typeof approvedProposals === "object" && approvedProposals !== null) {
      // Then, check if the currentUserId key exists in the approvedProposals
      const userProposals = approvedProposals[currentUserId];
      // Finally, check if 'fulfillment' key exists under the user's proposals
      return userProposals && "fulfillment" in userProposals;
    }
    return false; // Return false if approvedProposals is null or not an object
  };

  // Using the function to determine if the current user has fulfillment
  const currentUserHasFulfillment = checkUserFulfillment(
    props.task.approved_proposals,
    currentUser.uid,
  );
  //console.log('Current user has fulfillment:', currentUserHasFulfillment);

  const handleVideoRevisionClick = () => {
    setVideoRevision(true);
    // Call the function in InfluencerSendVideoRevision here if needed
  };

  var task_time = props.task.date_issued;
  var currentdate = new Date();

  var taskdate;
  if (task_time)
    taskdate = task_time
      .substring(0, 10)
      .split("-")
      .map(function (item) {
        return parseInt(item, 10);
      });

  var current_date =
    currentdate.getFullYear() +
    "," +
    (currentdate.getMonth() + 1) +
    "," +
    currentdate.getDate();

  var taskdate_b = current_date.split(",").map(function (item) {
    return parseInt(item, 10);
  });

  var a = moment(taskdate);

  var b = moment(taskdate_b);

  //let timeLeft = b.diff(a, 'days');

  // console.log(timeLeft);
  //console.log("APPROVED PROPOOSALS", props.task.approved_proposals);
  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const submitPost = () => {
    setAddPostModal(!addPostModal);
  };

  const influencerDetailsHandler = () => {
    setDeliverables(!deliverables);
  };

  const revisionHandle = () => {
    setVideoRevision(!videoRevision);
  };
  const proposalHandle = () => {
    setproposalTask(true);
  };
  const deleteHandle = () => {
    setDeleteTask(true);
  };
  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };

  const statusButtonMappings = {
    drafted: { text: "Draft Submitted", bg: "bg-Blue" },
    approved: {
      text: "Send Deliverables",
      onClick: influencerDetailsHandler,
      bg: "bg-Blue",
    },
    revision: {
      text: "Revision Needed",
      onClick: revisionHandle,
      bg: "bg-red",
    },
    completed: { text: "Completed", bg: "bg-gray-800" },
    pending: {
      text: "Pending Approval",
      onClick: detailsHander,
      bg: "bg-gray-400",
    },
    submitted: { text: "Submit Post Link", onClick: submitPost, bg: "bg-Blue" },
    denied: { text: "Proposal Denied", bg: "bg-red-500" },
  };
  //console.log(props.task.proposals);
  const statusConfig = statusButtonMappings[props.task.status];
  const isCurrentUserCreator = props.task.creator_id === currentUser.uid;
  const proposals =
    props.task && props.task.proposals ? props.task.proposals : {};
  const matchingProposals = proposals
    ? Object.values(proposals).filter(function (proposal) {
        return proposal && proposal.creator_id === currentUser.uid;
      })
    : [];

  const lastMatchingProposal =
    matchingProposals.length > 0
      ? matchingProposals[matchingProposals.length - 1]
      : undefined;

  let instagramRate, tiktokRate;

  function calculateRate(tiktokRate, instagramRate, add_ons) {
    const baseRate = 75;
    let rate = baseRate;

    if (tiktokRate) {
      rate += tiktokRate / 100;
    }
    if (instagramRate) {
      rate += instagramRate / 100;
    }
    if (add_ons) {
      const addOnsRate = add_ons.reduce(
        (total, addon) => total + addon.price,
        0,
      );
      rate += addOnsRate;
    }

    return rate.toFixed(2);
  }

  if (lastMatchingProposal && lastMatchingProposal.creator_socials) {
    if (
      lastMatchingProposal.creator_socials.instagram &&
      lastMatchingProposal.creator_socials.instagram.suggested_rate
    ) {
      instagramRate =
        lastMatchingProposal.creator_socials.instagram.suggested_rate;
    } else {
      // console.log('No Instagram suggested rate found for the current user.');
    }

    if (
      lastMatchingProposal.creator_socials.tiktok &&
      lastMatchingProposal.creator_socials.tiktok.performance &&
      lastMatchingProposal.creator_socials.tiktok.performance.suggestedRate
    ) {
      tiktokRate =
        lastMatchingProposal.creator_socials.tiktok.performance.suggestedRate;
    } else {
      // console.log('No TikTok suggested rate found for the current user.');
    }
  } else {
    // console.log('No matching proposal with complete information found for the current user.');
  }
  // console.log("proposal", lastMatchingProposal);
  return (
    <>
      <div
        onClick={() =>
          props.task.status === "submitted"
            ? submitPost()
            : props.task.status === "approved"
            ? influencerDetailsHandler()
            : props.task.status === "revision"
            ? revisionHandle()
            : props.task.status === "completed"
            ? () => {}
            : props.task.status === "pending"
            ? proposalHandle()
            : props.task.status === "drafted" // Display proposal modal for drafted tasks
            ? proposalHandle()
            : props.task.status === "denied" // Display proposal modal for drafted tasks
            ? () => {}
            : detailsHander()
        }
      >
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
          <div className="relative w-full h-full">
            <img
              src={
                props.task && props.task.brand_photo
                  ? props.task.brand_photo.includes("crafted-react-images")
                    ? BRIEF_ASSET_URL
                    : props.task.brand_photo
                  : BRIEF_ASSET_URL
              }
              className="rounded-lg w-full 2xl:h-15 xs:h-6rem md:h-6rem bg-white object-contain"
              alt="img"
            />
            {props.task &&
              props.task.platform &&
              props.task.platform.includes("Instagram") && (
                <div className="absolute bottom-0 left-2">
                  <div className="instagram w-4 h-4"></div>
                </div>
              )}
            {props.task &&
              props.task.platform &&
              props.task.platform.includes("TikTok") && (
                <div
                  className={`absolute bottom-0 ${
                    props.task.platform.length === 1 &&
                    props.task.platform.includes("TikTok")
                      ? "left-2"
                      : "left-7"
                  }`}
                >
                  <div className="tiktok w-4 h-4"></div>
                </div>
              )}
            {currentUserHasFulfillment && (
              <div className="absolute top-0 right-0 p-1">
                <FiGift
                  title="Fulfillment Sent"
                  className="text-white p-0.5 bg-red rounded font-extrabold w-5 h-5"
                />
              </div>
            )}
          </div>
          <div className="lg:text-3xl md:text-xs text-center truncate overflow-hidden leading-tight mt-0.5 md:w-36">
            {props.task.name}
          </div>
          <h2 className="font-bold">{props.task.brand_name}</h2>
          <div className="text-lg my-2 mb-1 flex text-Blue font-bold text-center">
            {props.task &&
              (props.task.payment_amount
                ? // If payment_amount exists, convert to dollars and format
                  `$${(props.task.payment_amount / 100).toFixed(2)}`
                : // The existing complex calculation logic for when payment_amount doesn't exist
                  "$" +
                  (props.task.platform &&
                  props.task.platform.includes("Instagram") &&
                  props.task.platform.includes("TikTok")
                    ? // Your existing logic for both Instagram and TikTok
                      calculateRate(
                        tiktokRate,
                        instagramRate,
                        props.task.add_ons,
                      )
                    : props.task.platform &&
                      props.task.platform.includes("Instagram")
                    ? // Your existing logic for Instagram only
                      calculateRate(null, instagramRate, props.task.add_ons)
                    : props.task.platform &&
                      props.task.platform.includes("TikTok")
                    ? // Your existing logic for TikTok only
                      calculateRate(tiktokRate, null, props.task.add_ons)
                    : // Your existing default calculation logic
                      calculateRate(null, null, props.task.add_ons)))}{" "}
            + free product
          </div>
          <div className="text-base mb-1 font-bold tracking-tighter text-center">
            {props.task.add_ons &&
              props.task.add_ons.map((el, i) => (
                <span>
                  {" "}
                  {i !== 0 && "+"} {el.name}
                </span>
              ))}
          </div>
          <div className="text-base mb-1 font-bold"></div>
        </div>
      </div>
      <div className="items-center justify-center text-center">
        {statusConfig && isCurrentUserCreator && (
          <CustomButton
            className={`text-white px-2 mr-3 outline-none rounded-lg text-xs ${statusConfig.bg}`}
            onClick={statusConfig.onClick}
          >
            {statusConfig.text}
          </CustomButton>
        )}
        {statusConfig && !isCurrentUserCreator && (
          <span
            className={`text-white px-2 mr-3 rounded-lg outline-none text-xs ${statusConfig.bg}`}
          >
            {statusConfig.text}
          </span>
        )}
      </div>
      {delTask ? (
        <DeleteModalTask
          open={delTask}
          onClose={() => setDelTask(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {proposalTask ? (
        <InfluencerApprovalModalTask
          open={proposalTask}
          task={props.task}
          onClose={() => setproposalTask(false)}
          id={props.id}
        />
      ) : null}
      {update ? (
        <UpdateTask open={update} onClose={updateHandle} id={props.id} />
      ) : null}
      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {videoRevision ? (
        <InfluencerSendVideoRevision
          open={videoRevision}
          fetchInfluencerTasks={props.fetchInfluencerTasks}
          onClose={() => setVideoRevision(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {deleteTask ? (
        <TaskDeleteModal
          open={deleteTask}
          task={props.task}
          close={() => setDeleteTask(false)}
          id={props.id}
        />
      ) : null}
      {addPostModal ? (
        <InfluencerSubmitLink
          open={addPostModal}
          fetchInfluencerTasks={props.fetchInfluencerTasks}
          onClose={submitPost}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {/*Added deliverable modal for influencer tasks */}
      {deliverables ? (
        <InfluencerSendDeliverables
          open={deliverables}
          fetchInfluencerTasks={props.fetchInfluencerTasks}
          onClose={() => setDeliverables(false)}
          task={props.task}
          currentUserHasFulfillment={currentUserHasFulfillment}
          id={props.id}
        />
      ) : null}
    </>
  );
}

export default TaskCard;

import React, { useState } from "react";
import DeleteModalTask from "./TaskDeleteModal";
import ApprovalModalTask from "./ApprovalModalTask";
import TaskDetailsModal from "./SendTaskDeliverables";
import TaskDeleteModal from "./TaskDeleteModal";
import moment from "moment";
import { FiGift } from "react-icons/fi";
import UpdateTask from "./UpdateTask";
import { updateCurrentUser } from "firebase/auth";
import { useAuth } from "../../Context/AuthContext";
import SendVideoRevision from "./SendVideoRevision";
import SubmitTiktokCode from "./SubmitTiktokCode";
import CustomButton from "../../utils/customButton";

const BRIEF_ASSET_URL = process.env.REACT_APP_BRIEF_ASSET_URL;

function TaskCard(props) {
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [videoRevision, setVideoRevision] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const [submission, setSubmission] = useState(false);
  const { currentUser } = useAuth();

  var task_time = props.task.date_issued;
  var currentdate = new Date();

  var taskdate;
  if (task_time)
    taskdate = task_time
      .substring(0, 10)
      .split("-")
      .map(function (item) {
        return parseInt(item, 10);
      });

  var current_date =
    currentdate.getFullYear() +
    "," +
    (currentdate.getMonth() + 1) +
    "," +
    currentdate.getDate();

  var taskdate_b = current_date.split(",").map(function (item) {
    return parseInt(item, 10);
  });

  var a = moment(taskdate);

  var b = moment(taskdate_b);

  let timeLeft = b.diff(a, "days");

  //console.log(timeLeft);

  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const revisionHandle = () => {
    setVideoRevision(!videoRevision);
  };
  const proposalHandle = () => {
    setproposalTask(true);
  };
  const deleteHandle = () => {
    setDeleteTask(true);
  };
  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };
  const submissionHandle = () => {
    setSubmission(!submission);
  };

  const checkUserFulfillment = (approvedProposals, currentUserId) => {
    // First, check if approvedProposals is an object and not null
    if (typeof approvedProposals === "object" && approvedProposals !== null) {
      // Then, check if the currentUserId key exists in the approvedProposals
      const userProposals = approvedProposals[currentUserId];
      // Finally, check if 'fulfillment' key exists under the user's proposals
      return userProposals && "fulfillment" in userProposals;
    }
    return false; // Return false if approvedProposals is null or not an object
  };

  // Using the function to determine if the current user has fulfillment
  const currentUserHasFulfillment = checkUserFulfillment(
    props.task.accepted_proposals,
    currentUser.uid,
  );

  const statusButtonMappings = {
    drafted: { text: "Draft Submitted" },
    approved: { text: "Send Deliverables", onClick: detailsHander },
    revision: { text: "Revision Needed", onClick: revisionHandle },
    completed: { text: "Completed" },
    pending: { text: "Pending Approval" },
  };
  const statusConfig = statusButtonMappings[props.task.status];
  const isCurrentUserCreator = props.task.creator_id === currentUser.uid;
  return (
    <>
      <div
        onClick={() =>
          props.task.status === "approved"
            ? detailsHander()
            : props.task.status === "revisions"
            ? revisionHandle()
            : props.task.status === "pending"
            ? proposalHandle()
            : props.task.status === "submitted"
            ? submissionHandle()
            : props.task.status === "completed"
            ? () => {}
            : props.task.status === "drafted" // Display proposal modal for drafted tasks
            ? () => {}
            : detailsHander()
        }
      >
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
          <div className="relative w-full h-full">
            <img
              src={
                props.task && props.task.brand_photo
                  ? props.task.brand_photo.includes("crafted-react-images")
                    ? BRIEF_ASSET_URL
                    : props.task.brand_photo
                  : BRIEF_ASSET_URL
              }
              className="rounded-lg w-full 2xl:h-15 xs:h-6rem md:h-6rem bg-white object-contain"
              alt="img"
            />
            {props.task &&
              props.task.add_ons &&
              props.task.add_ons.some(
                (addOn) => addOn !== null && addOn.name === "Whitelist",
              ) && (
                <div className="absolute text-white px-2 bg-gray-900 rounded-md top-2 left-2">
                  <div className="text-0.7">Whitelisting</div>
                </div>
              )}
            {currentUserHasFulfillment && (
              <div className="absolute top-0 right-0 p-1">
                <FiGift
                  title="Fulfillment Sent"
                  className="text-white p-0.5 bg-red rounded font-extrabold w-5 h-5"
                />
              </div>
            )}
            {props.task.deadline &&
              moment().isSameOrAfter(
                moment(props.task.deadline).subtract(3, "days"),
              ) && (
                <div className="absolute top-0 left-0 p-1">
                  <div
                    title="Deadline Approaching"
                    className="text-white p-0.5 bg-red rounded font-extrabold w-5 h-5 flex items-center justify-center"
                  >
                    !!!
                  </div>
                </div>
              )}
            <div className="text-center w-full overflow-hidden truncate  line-camp-2">
              {(props.task && props.task.name) || props.task.note1}
            </div>
            <div className="text-lg my-2 mb-1 text-Blue font-bold text-center">
              {" "}
              ${(props.task.price / 100).toFixed(2)} + free product
            </div>
            <div className="text-base mb-1 font-bold tracking-tighter text-center">
              1 Video
              {props.task && props.task.variations && <>, Versions</>}
              {props.task &&
                props.task.add_ons &&
                props.task.add_ons.map((el, i) => (
                  <span key={i}>
                    ,{i !== 0} {el && el.name && el.name}
                  </span>
                ))}
              {props.task.recipe_videos !== undefined &&
                props.task.recipe_videos !== 0 &&
                props.task.recipe_videos + " Recipe Video"}
            </div>
            <div className="text-base mb-1 font-bold"></div>
          </div>
        </div>
      </div>
      <div className="items-center justify-center text-center">
        {props.task.status === "drafted" && (
          <>
            <CustomButton className="bg-Blue  text-white px-2 mr-3 rounded-lg outline-none text-xs">
              Draft Submitted
            </CustomButton>
          </>
        )}
        {props.task.status === "pending" && (
          <>
            <button className="bg-gray-400  text-white px-2 mr-3 rounded-lg outline-none text-xs">
              Pending Approval
            </button>
          </>
        )}

        {props.task.status === "approved" && (
          <>
            <CustomButton
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              onClick={() => detailsHander()}
            >
              Send Deliverables
            </CustomButton>
          </>
        )}
        {props.task.status === "submitted" && (
          <>
            <CustomButton
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              onClick={() => submissionHandle()}
            >
              Submit TikTok Ad Code
            </CustomButton>
          </>
        )}
        {/* {props.task.status === 'draft_approved' && props.task.creator_id === currentUser.uid && (
          <>
            <button
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              // onClick={() => detailsHander()}
              >
              Draft Approved
            </button>
          </>
        )} */}

        {/* {props.task.status === 'approved' && props.task.creator_id !== currentUser.uid && (
          <>
            <button
              className="bg-gray-800 text-white px-2 mr-3 rounded-lg outline-none text-xs"
              >
              Approved
            </button>
          </>
        )} */}

        {props.task.status === "revisions" && (
          <>
            <button
              className="bg-red bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              onClick={() => revisionHandle()}
            >
              Revision Needed
            </button>
          </>
        )}
        {props.task.status === "completed" && (
          <>
            <span
              className="bg-gray-800 text-white px-2 mr-3 rounded-lg outline-none text-xs"
              // onClick={() => detailsHander()}
            >
              Completed
            </span>
          </>
        )}
      </div>
      {delTask ? (
        <DeleteModalTask
          open={delTask}
          onClose={() => setDelTask(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {proposalTask ? (
        <ApprovalModalTask
          open={proposalTask}
          task={props.task}
          onClose={() => setproposalTask(false)}
          id={props.id}
        />
      ) : null}
      {update ? (
        <UpdateTask open={update} onClose={updateHandle} id={props.id} />
      ) : null}
      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          currentUserHasFulfillment={currentUserHasFulfillment}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {videoRevision ? (
        <SendVideoRevision
          open={videoRevision}
          close={() => setVideoRevision(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {submission ? (
        <SubmitTiktokCode
          open={submission}
          close={() => setSubmission(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {deleteTask ? (
        <TaskDeleteModal
          open={deleteTask}
          task={props.task}
          close={() => setDeleteTask(false)}
          id={props.id}
        />
      ) : null}
    </>
  );
}

export default TaskCard;
